
<div id='pag7' style="margin: 0 15vw 0 5vw ">
    <div class="row" style="justify-content: center;">
      <div  style="min-width: 300px;justify-content: center;text-align: center;width: 30%;">
        <img alt='' style="width: 100%;" src="assets/Casos de uso/ilustras/ilustra 07 casos.png"/>
        
      </div>
      <div  style="padding: 0 5vw 0 0; min-width: 300px; width: 70%;">
        <h2 style="margin-top: 3rem !important; margin-bottom: 3rem !important">Caso de uso no setor imobiliário:   </h2>
        
        <p>Fomos procurados por uma empresa que já utilizava o serviço de coleta de dados há alguns anos com um software adquirido de outro fornecedor, contudo, estava sofrendo com instabilidades, falta de mão de obra para a utilização da ferramenta, dados incompletos, e falta de estrutura para a coleta massiva.  </p>
        
        <h5>Qual era a necessidade?</h5>
        <p>Precisavam de uma solução estável que coletasse um grande volume de dados de forma confiável, sem ter a preocupação com manutenção de servidores e softwares, além de treinamento técnico de pessoas. </p>

        <img alt='' style='margin: 
 30px 30px  30px 0;width: 100%;' src='assets/Casos de uso/icones/imob.webp'/>
        <h5 style="margin-top: 25px;">Solução entregue:</h5>
      
        <p>
          Iniciamos rodando uma POC para a cidade de São Paulo, e em apenas 3 meses já tínhamos coletado e indexado mais dados do que eles haviam armazenado nos últimos 5 anos.  Como resultado, expandimos o projeto para todas as capitais e principais cidades brasileiras.         </p>
        <p>
          A necessidade da coleta de dados já tinha sido resolvida, em contrapartida, o cliente começou a ter dificuldades com o consumo e armazenamento do grande volume de dados que estávamos entregando, e por isso, precisou se readequar para que fosse possível processar e armazenar toda a informação recebida. 
        </p>
        <p>Hoje, já são mais de 2 anos de parceria com esse grande player do mercado imobiliário, com projetos construídos e orbitando ao redor desses dados coletados, como previsão de mercado, busca de imóveis, índice de crescimento e indicadores de oportunidade. Tudo isso através da tecnologia Datallog. </p>
  
      </div>
    </div>
  </div>
  
  <div class="album py-5 bg-light" style="">
    <div class="">
      <h2 style="text-align: center;">Veja outros casos de uso</h2>
      <div class="row "style="width:70vw;margin: 50px 15vw 0 15vw;">


        <div class="col" style='margin:5px;'>
        <a href="#/casos/nfse">
          <div class="card shadow-sm" style='height: 100%'>
            <h3  style="text-align: center;margin:15px">Leitor de NFS-e</h3>
            <div class="card-body" style='min-width: 250px;text-align:center; padding: 0px;'>
              <img alt='' src='assets/Casos de uso/icones/inventti_use_case-3.png' style="width: 100%; height:100%; margin:0;padding:0" class=''/>
              
              
            </div>
          </div>
        </a>
      </div>

        <div class="col" style='margin:5px;'>
        <a href="#/casos/automotivo">
          <div class="card shadow-sm" style='height: 100%'>
            <h3  style="text-align: center;margin:15px">Monitoramento de anúncios</h3>
            <div class="card-body" style='min-width: 250px;text-align:center; padding: 0px;'>
              <img alt='' src='assets/Casos de uso/icones/car_image.webp' style="width: 100%; height:100%; margin:0;padding:0" class=''/>
              
              
            </div>
          </div>
        </a>
      </div>
  
        <div class="col" style='margin:5px;'>
          <a href="#/casos/licitacoes">
            <div class="card shadow-sm" style='height: 100%;'>
              <h3  style="text-align: center;margin:15px">Dados de licitações</h3>
              <div class="card-body" style='min-width: 250px;text-align:center; padding:0px;'>
                <img alt='' src='assets/Casos de uso/icones/licita.jpg' style="width: 100%; height:100%; margin:0;padding:0" class=''/>
                
              </div>
            </div>
          </a>
        </div>
      
      
      
      </div>
    </div>    
  </div>
  
  
  <div class="" style="">
    <div class="col" style=''>
      <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 25px 15vw;'>
        <div class="card-body" style='min-width: 300px;'>
          <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
      a datallog pode ajudar seu negócio?</h2>
        </div>

        <div style="margin:0 50px 30px 50px ;text-align:right;">
    <a href="#/contato" style="display: inline-block;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    padding: 0.375rem 0.75rem;
    color: #000;
    border: 2px solid #000;
    padding: 15px 22px 15px 22px;
    border-radius: 0;
    font-weight: 800;
    margin: 0;
    width: auto;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: .5px;
    -webkit-transition: background .4s,border .4s,color .4s;
    transition: background .4s,border .4s,color .4s;
    white-space: break-spaces;
    ">
<span>Conte-nos como podemos te ajudar</span>

<div style="

display: block;
position: absolute;
top: calc(100% + 2px);
left: calc(0% - 2px);
width: calc(100% + (2 * 2px));
border-top: 4px solid #000;
border-left: 8px solid rgba(0,0,0,0);
border-right: 8px solid rgba(0,0,0,0);
height: 0;
margin:0;
padding:0;
-webkit-box-sizing: border-box;
box-sizing: border-box;"></div>
    </a>
        </div>
      
      
      
      
      
      </div>    
    </div>
  </div>    
  
  
  