<script>
  export let style = '';
</script>

<section class="container" {style}>
  <div class="row">
    <div class="col-md-10 mx-auto">
      <slot />
    </div>
  </div>
</section>

<style>

</style>