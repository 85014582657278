
<div id='pag7' style="margin: 0 15vw 0 5vw ">
  <div class="row" style="justify-content: center;">
    <div  style="min-width: 300px;justify-content: center;text-align: center;width: 30%;">
      <img alt='' style="width: 100%;" src="assets/Casos de uso/ilustras/ilustra 07 casos.png"/>
      
    </div>
    <div  style="padding: 0 5vw 0 0; min-width: 300px; width: 70%;">
      <h2 style="margin-top: 3rem !important; margin-bottom: 3rem !important">Caso de uso no setor de compras públicas</h2>
      <p>Uma das maiores empresas de tecnologia para licitantes entrou em contato conosco com uma necessidade que precisava ser resolvida o mais rápido possível, já que seu crescimento estava estagnado e estavam perdendo clientes, ocasionando em uma diminuição do faturamento. </p>
      <p><b>O que a tecnologia do negócio ofertava:</b> um módulo de busca de editais para prospecção de oportunidades e outro de monitoramento de convocações e citações no chat dos pregões eletrônicos.  </p>

      <h5>Qual era a necessidade?</h5>

      <p>A empresa havia desenvolvido o próprio sistema de raspagem de dados para a coleta dos editais e das movimentações no chat, porém, a falta de padronização e a escolha inadequada da linguagem de programação tornaram o processo de desenvolvimento lento, ocasionando em problemas como a falta de dados e entrega de dados não confiáveis.  Consequentemente, os problemas apresentados impactaram nas entregas aos clientes gerando quebras de contratos.   </p>
      <p>A falta de uma plataforma dificultava o monitoramento e operação dos coletores, além disso, o sistema não tinha arquitetura para escalar. Em pouco tempo, a operação tornou-se uma grande dor de cabeça tanto para manutenção quanto para o desenvolvimento de novos coletores.  </p>




      <img alt='' style='margin: 
 30px 30px  30px 0;width: 100%;' src='assets/Casos de uso/icones/licita.jpg'/>
      <h5 style="margin-top: 25px;">Solução entregue:</h5>
    
      <p>
        Uma das condições apresentadas pelo cliente para iniciarmos o projeto era mantermos o desenvolvimento e a operação dentro da empresa. Para que essa condição fosse atendida, o melhor caminho seria a adoção da tecnologia Datallog (SDK/plataforma) por parte da equipe de desenvolvimento, incluindo impreterivelmente o treinamento de todos os envolvidos com as melhores técnicas para coleta e processamento de dados WEB.      </p>
      <p>
        <b>O resultado:</b> Em menos de 6 meses quase toda a operação já tinha sido embarcada em nossa tecnologia, com o SDK a equipe ganhou não só agilidade no desenvolvimento de refatoração, como também possibilidade para escalar a operação dos robôs sem preocupação com o provisionamento de servidores. Com o uso da API, a criação de rotinas de execução facilitou a integração entre a aplicação do cliente e o processo de coleta de dados. E os ganhos não param por aí, a tecnologia serveless possibilitou a redução considerável dos custos com servidores.       </p>
      <p>A partir do momento em que o processo de coleta de dados não era mais um gargalo, a empresa pôde focar nos resultados e em impulsionar o seu crescimento, investindo em marketing e melhorias em seu produto. Após poucos meses de implementação da tecnologia, a taxa de saídas de clientes foi reduzida em 50% e dedicam-se ao desenvolvimento de novos módulos.  </p>

    </div>
  </div>
</div>
  







<div class="album py-5 bg-light" style="">
  <div class="">
    <h2 style="text-align: center;">Veja outros casos de uso</h2>
    <div class="row "style="width:70vw;margin: 50px 15vw 0 15vw;">





      <div class="col" style='margin:5px;'>
        <a href="#/casos/nfse">
          <div class="card shadow-sm" style='height: 100%'>
            <h3  style="text-align: center;margin:15px">Leitor de NFS-e</h3>
            <div class="card-body" style='min-width: 250px;text-align:center; padding: 0px;'>
              <img alt='' src='assets/Casos de uso/icones/inventti_use_case-3.png' style="width: 100%; height:100%; margin:0;padding:0" class=''/>
              
              
            </div>
          </div>
        </a>
      </div>
      
      
      <div class="col" style='margin:5px;'>
        <a href="#/casos/imobiliario">
          <div class="card shadow-sm" style='height: 100%;'>
            <h3  style="text-align: center;margin:15px">Dados de imóveis</h3>
            <div class="card-body" style='min-width: 250px; text-align:center; padding: 0px'>
              
              <img alt='' src='assets/Casos de uso/icones/imob.webp' style="width: 100%; height:100%; margin:0;padding:0" class=''/>
              
              
            </div>
          </div>
        </a>
      </div>
      
      
      <div class="col" style='margin:5px;'>
        <a href="#/casos/automotivo">
          <div class="card shadow-sm" style='height: 100%'>
            <h3  style="text-align: center;margin:15px">Monitoramento de anúncios</h3>
            <div class="card-body" style='min-width: 250px;text-align:center; padding: 0px;'>
              <img alt='' src='assets/Casos de uso/icones/car_image.webp' style="width: 100%; height:100%; margin:0;padding:0" class=''/>
              
              
            </div>
          </div>
        </a>
      </div>

    </div>
  </div>    
</div>







<div class="" style="">
  <div class="col" style=''>
    <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0 15vw;'>
      <div class="card-body" style='min-width: 300px;'>
        <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
    a datallog pode ajudar seu negócio?</h2>
      </div>    
      <div style="margin:0 50px 30px 50px ;text-align:right;">
        <a href="#/contato" style="display: inline-block;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #fff;
        padding: 0.375rem 0.75rem;
        color: #000;
        border: 2px solid #000;
        padding: 10px 22px;
        border-radius: 0;
        font-weight: 800;
        margin: 0;
        width: auto;
        position: relative;
        font-size: 1rem;
        line-height: 1rem;
        text-transform: uppercase;
        letter-spacing: .5px;
        -webkit-transition: background .4s,border .4s,color .4s;
        transition: background .4s,border .4s,color .4s;
        white-space: break-spaces;
        ">
    <span>Conte-nos como podemos te ajudar</span>
    
    <div style="
    
    display: block;
    position: absolute;
    top: calc(100% + 2px);
    left: calc(0% - 2px);
    width: calc(100% + (2 * 2px));
    border-top: 4px solid #000;
    border-left: 8px solid rgba(0,0,0,0);
    border-right: 8px solid rgba(0,0,0,0);
    height: 0;
    margin:0;
    padding:0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    
    "></div>
        </a>
      </div>
    </div>    
  </div>
</div>    


