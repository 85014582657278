import * as Pages from './pages/index';

const urlParams = new URLSearchParams(window.location.search);

const pagesObject = {
  '/': Pages.Pagina1,
};

const quantidadePaginas = Object.keys(Pages).length;


pagesObject[`/home`] = Pages.Pagina1;
pagesObject[`/solucoes`] = Pages.Pagina2;

pagesObject[`/solucoes/sdk`] = Pages.Pagina2_1;
pagesObject[`/solucoes/imob`] = Pages.Pagina2_2;
pagesObject[`/solucoes/licita`] = Pages.Pagina2_3;
pagesObject[`/solucoes/cnd`] = Pages.Pagina2_4;
pagesObject[`/solucoes/cnd_old`] = Pages.Pagina2_4_old;
pagesObject[`/solucoes/doc`] = Pages.Pagina2_5;
pagesObject[`/solucoes/prospect`] = Pages.Pagina2_6;

pagesObject[`/faq`] = Pages.Pagina3;
pagesObject[`/casos`] = Pages.Pagina4;

pagesObject[`/casos/analise`] = Pages.Pagina4_1;
pagesObject[`/casos/automacao`] = Pages.Pagina4_2;
pagesObject[`/casos/monitoramento`] = Pages.Pagina4_3;
pagesObject[`/casos/processos`] = Pages.Pagina4_4;
pagesObject[`/casos/avaliacao`] = Pages.Pagina4_5;
pagesObject[`/casos/prospeccao`] = Pages.Pagina4_6;

pagesObject[`/blog`] = Pages.Pagina5;
pagesObject[`/consultas`] = Pages.Pagina6;
pagesObject[`/contato`] = Pages.Pagina7;
pagesObject[`/contato_old`] = Pages.PaginaOld7;

pagesObject[`/casos/imobiliario`] = Pages.Pagina11;
pagesObject[`/casos/automotivo`] = Pages.Pagina12;
pagesObject[`/casos/licitacoes`] = Pages.Pagina13;
pagesObject[`/casos/nfse`] = Pages.PaginaInventti;

//pagesObject[`/veja_caso`] = Pages.Pagina10;

//pagesObject[`/consulta`] = Pages.Pagina50;
pagesObject[`/consultas/ANTT`] = Pages.Pagina51;
pagesObject[`/consultas/Banco_do_Brasil`] = Pages.Pagina52;
pagesObject[`/consultas/CCC`] = Pages.Pagina53;
pagesObject[`/consultas/CEIS`] = Pages.Pagina54;
pagesObject[`/consultas/CENPROT`] = Pages.Pagina55;
pagesObject[`/consultas/CNEP`] = Pages.Pagina56;
pagesObject[`/consultas/CNJ`] = Pages.Pagina57;
pagesObject[`/consultas/Detran_AC`] = Pages.Pagina58;
pagesObject[`/consultas/Detran_AL`] = Pages.Pagina59;
pagesObject[`/consultas/Detran_AM`] = Pages.Pagina60;
pagesObject[`/consultas/Detran_AP`] = Pages.Pagina61;
pagesObject[`/consultas/Detran_BA`] = Pages.Pagina62;
pagesObject[`/consultas/Detran_CE`] = Pages.Pagina63;
pagesObject[`/consultas/Detran_DF`] = Pages.Pagina64;
pagesObject[`/consultas/Detran_ES`] = Pages.Pagina65;
pagesObject[`/consultas/Detran_GO`] = Pages.Pagina66;
pagesObject[`/consultas/Detran_MA`] = Pages.Pagina67;
pagesObject[`/consultas/Detran_MG`] = Pages.Pagina68;
pagesObject[`/consultas/Detran_MS`] = Pages.Pagina69;
pagesObject[`/consultas/Detran_MT`] = Pages.Pagina70;
pagesObject[`/consultas/Detran_PA`] = Pages.Pagina71;
pagesObject[`/consultas/Detran_PB`] = Pages.Pagina72;
pagesObject[`/consultas/Detran_PE`] = Pages.Pagina73;
pagesObject[`/consultas/Detran_PI`] = Pages.Pagina74;
pagesObject[`/consultas/Detran_PR`] = Pages.Pagina75;
pagesObject[`/consultas/Detran_RJ`] = Pages.Pagina76;
pagesObject[`/consultas/Detran_RN`] = Pages.Pagina77;
pagesObject[`/consultas/Detran_RO`] = Pages.Pagina78;
pagesObject[`/consultas/Detran_RR`] = Pages.Pagina79;
pagesObject[`/consultas/Detran_RS`] = Pages.Pagina80;
pagesObject[`/consultas/Detran_SC`] = Pages.Pagina81;
pagesObject[`/consultas/Detran_SE`] = Pages.Pagina82;
pagesObject[`/consultas/Detran_SP`] = Pages.Pagina83;
pagesObject[`/consultas/Detran_TO`] = Pages.Pagina84;
pagesObject[`/consultas/Fazenda_MG`] = Pages.Pagina85;
pagesObject[`/consultas/Fazenda_SP_CADIN`] = Pages.Pagina86;
pagesObject[`/consultas/Fazenda_SP`] = Pages.Pagina87;
pagesObject[`/consultas/FGTS`] = Pages.Pagina88;
pagesObject[`/consultas/IBAMA`] = Pages.Pagina89;
pagesObject[`/consultas/INSS`] = Pages.Pagina90;
pagesObject[`/consultas/Junta_Comercial`] = Pages.Pagina91;
pagesObject[`/consultas/Lava_Jato`] = Pages.Pagina92;
pagesObject[`/consultas/MEI`] = Pages.Pagina93;
pagesObject[`/consultas/MPSP`] = Pages.Pagina94;
pagesObject[`/consultas/MPF`] = Pages.Pagina95;
pagesObject[`/consultas/MTE`] = Pages.Pagina96;
pagesObject[`/consultas/PGE_SP_CERTIDAO`] = Pages.Pagina97;
pagesObject[`/consultas/PGE_SP_DIVIDA`] = Pages.Pagina98;
pagesObject[`/consultas/PGFN`] = Pages.Pagina99;
pagesObject[`/consultas/Prefeitura_BH`] = Pages.Pagina100;
pagesObject[`/consultas/Prefeitura_SP_IMOB`] = Pages.Pagina101;
pagesObject[`/consultas/Prefeitura_SP_MOB`] = Pages.Pagina102;
pagesObject[`/consultas/Prefeitura_SP_IPTU`] = Pages.Pagina103;
pagesObject[`/consultas/Prefeitura_SP_EXTRATO`] = Pages.Pagina104;
pagesObject[`/consultas/Receita1`] = Pages.Pagina105;
pagesObject[`/consultas/Receita2`] = Pages.Pagina106;
pagesObject[`/consultas/Receita3`] = Pages.Pagina107;
pagesObject[`/consultas/Receita4`] = Pages.Pagina108;
pagesObject[`/consultas/Receita5`] = Pages.Pagina109;
pagesObject[`/consultas/Receita6`] = Pages.Pagina110;
pagesObject[`/consultas/Receita7`] = Pages.Pagina111;
pagesObject[`/consultas/Registradores`] = Pages.Pagina112;
pagesObject[`/consultas/Secretaria`] = Pages.Pagina113;
pagesObject[`/consultas/SICAF`] = Pages.Pagina114;
pagesObject[`/consultas/SICAR`] = Pages.Pagina115;
pagesObject[`/consultas/SNCR`] = Pages.Pagina116;
pagesObject[`/consultas/TCU`] = Pages.Pagina117;
pagesObject[`/consultas/TJMG`] = Pages.Pagina118;
pagesObject[`/consultas/TJRJ`] = Pages.Pagina119;
pagesObject[`/consultas/TJSP`] = Pages.Pagina120;
pagesObject[`/consultas/TRF`] = Pages.Pagina121;
pagesObject[`/consultas/TRT`] = Pages.Pagina122;
pagesObject[`/consultas/TST`] = Pages.Pagina123;
pagesObject[`/consultas/TST2`] = Pages.Pagina124;




const routes = !urlParams.has('routemap') ? pagesObject : new Map();

if (urlParams.has('routemap')) {
  routes.set('/', Pages.Pagina1);

  routes.set(`/solucoes`, Pages.Pagina2);
  routes.set(`/solucoes/sdk`, Pages.Pagina2_1);
  routes.set(`/solucoes/imob`, Pages.Pagina2_2);
  routes.set(`/solucoes/licita`, Pages.Pagina2_3);
  routes.set(`/solucoes/cnd`, Pages.Pagina2_4);
  routes.set(`/solucoes/doc`, Pages.Pagina2_5);

  routes.set(`/casos`, Pages.Pagina4);
  routes.set(`/casos/analise`, Pages.Pagina4_1);
  routes.set(`/casos/automacao`, Pages.Pagina4_2);
  routes.set(`/casos/monitoramento`, Pages.Pagina4_3);
  routes.set(`/casos/processos`, Pages.Pagina4_4);
  routes.set(`/casos/avaliacao`, Pages.Pagina4_5);
  routes.set(`/casos/prospeccao`, Pages.Pagina4_6);

  routes.set(`/faq`, Pages.Pagina3);
  routes.set(`/blog`, Pages.Pagina5);
  routes.set(`/consultas`, Pages.Pagina6);
  routes.set(`/contato`, Pages.Pagina7);
  routes.set(`/contato_old`, Pages.PaginaOld7);

  //  routes.set(`/veja_caso`, Pages.Pagina10);

  //  routes.set(`/consultas/Detran_`, Pages.Pagina50);
  routes.set(`/consultas/ANTT`, Pages.Pagina51);
  routes.set(`/consultas/Banco_do_Brasil`, Pages.Pagina52);
  routes.set(`/consultas/CCC`, Pages.Pagina53);
  routes.set(`/consultas/CEIS`, Pages.Pagina54);
  routes.set(`/consultas/CENPROT`, Pages.Pagina55);
  routes.set(`/consultas/CNEP`, Pages.Pagina56);
  routes.set(`/consultas/CNJ`, Pages.Pagina57);
  routes.set(`/consultas/Detran_AC`, Pages.Pagina58);
  routes.set(`/consultas/Detran_AL`, Pages.Pagina59);
  routes.set(`/consultas/Detran_AM`, Pages.Pagina60);
  routes.set(`/consultas/Detran_AP`, Pages.Pagina61);
  routes.set(`/consultas/Detran_BA`, Pages.Pagina62);
  routes.set(`/consultas/Detran_CE`, Pages.Pagina63);
  routes.set(`/consultas/Detran_DF`, Pages.Pagina64);
  routes.set(`/consultas/Detran_ES`, Pages.Pagina65);
  routes.set(`/consultas/Detran_GO`, Pages.Pagina66);
  routes.set(`/consultas/Detran_MA`, Pages.Pagina67);
  routes.set(`/consultas/Detran_MG`, Pages.Pagina68);
  routes.set(`/consultas/Detran_MS`, Pages.Pagina69);
  routes.set(`/consultas/Detran_MT`, Pages.Pagina70);
  routes.set(`/consultas/Detran_PA`, Pages.Pagina71);
  routes.set(`/consultas/Detran_PB`, Pages.Pagina72);
  routes.set(`/consultas/Detran_PE`, Pages.Pagina73);
  routes.set(`/consultas/Detran_PI`, Pages.Pagina74);
  routes.set(`/consultas/Detran_PR`, Pages.Pagina75);
  routes.set(`/consultas/Detran_RJ`, Pages.Pagina76);
  routes.set(`/consultas/Detran_RN`, Pages.Pagina77);
  routes.set(`/consultas/Detran_RO`, Pages.Pagina78);
  routes.set(`/consultas/Detran_RR`, Pages.Pagina79);
  routes.set(`/consultas/Detran_RS`, Pages.Pagina80);
  routes.set(`/consultas/Detran_SC`, Pages.Pagina81);
  routes.set(`/consultas/Detran_SE`, Pages.Pagina82);
  routes.set(`/consultas/Detran_SP`, Pages.Pagina83);
  routes.set(`/consultas/Detran_TO`, Pages.Pagina84);
  routes.set(`/consultas/Fazenda_MG`, Pages.Pagina85);
  routes.set(`/consultas/Fazenda_SP_CADIN`, Pages.Pagina86);
  routes.set(`/consultas/Fazenda_SP`, Pages.Pagina87);
  routes.set(`/consultas/FGTS`, Pages.Pagina88);
  routes.set(`/consultas/IBAMA`, Pages.Pagina89);
  routes.set(`/consultas/INSS`, Pages.Pagina90);
  routes.set(`/consultas/Junta_Comercial`, Pages.Pagina91);
  routes.set(`/consultas/Lava_Jato`, Pages.Pagina92);
  routes.set(`/consultas/MEI`, Pages.Pagina93);
  routes.set(`/consultas/MPSP`, Pages.Pagina94);
  routes.set(`/consultas/MPF`, Pages.Pagina95);
  routes.set(`/consultas/MTE`, Pages.Pagina96);
  routes.set(`/consultas/PGE_SP_CERTIDAO`, Pages.Pagina97);
  routes.set(`/consultas/PGE_SP_DIVIDA`, Pages.Pagina98);
  routes.set(`/consultas/PGFN`, Pages.Pagina99);
  routes.set(`/consultas/Prefeitura_BH`, Pages.Pagina100);
  routes.set(`/consultas/Prefeitura_SP_IMOB`, Pages.Pagina101);
  routes.set(`/consultas/Prefeitura_SP_MOB`, Pages.Pagina102);
  routes.set(`/consultas/Prefeitura_SP_IPTU`, Pages.Pagina103);
  routes.set(`/consultas/Prefeitura_SP_EXTRATO`, Pages.Pagina104);
  routes.set(`/consultas/Receita1`, Pages.Pagina105);
  routes.set(`/consultas/Receita2`, Pages.Pagina106);
  routes.set(`/consultas/Receita3`, Pages.Pagina107);
  routes.set(`/consultas/Receita4`, Pages.Pagina108);
  routes.set(`/consultas/Receita5`, Pages.Pagina109);
  routes.set(`/consultas/Receita6`, Pages.Pagina110);
  routes.set(`/consultas/Receita7`, Pages.Pagina111);
  routes.set(`/consultas/Registradores`, Pages.Pagina112);
  routes.set(`/consultas/Secretaria`, Pages.Pagina113);
  routes.set(`/consultas/SICAF`, Pages.Pagina114);
  routes.set(`/consultas/SICAR`, Pages.Pagina115);
  routes.set(`/consultas/SNCR`, Pages.Pagina116);
  routes.set(`/consultas/TCU`, Pages.Pagina117);
  routes.set(`/consultas/TJMG`, Pages.Pagina118);
  routes.set(`/consultas/TJRJ`, Pages.Pagina119);
  routes.set(`/consultas/TJSP`, Pages.Pagina120);
  routes.set(`/consultas/TRF`, Pages.Pagina121);
  routes.set(`/consultas/TRT`, Pages.Pagina122);
  routes.set(`/consultas/TST`, Pages.Pagina123);
  routes.set(`/consultas/TST2`, Pages.Pagina124);


  routes.set(`/casos/imobiliario`, Pages.Pagina11);
  routes.set(`/casos/automotivo`, Pages.Pagina12);
  routes.set(`/casos/licitacoes`, Pages.Pagina13);
  routes.set(`/casos/nfse`, Pages.PaginaInventti);
}

export default routes;
