import { writable } from 'svelte/store';

const navegacaoStore = writable({
  sidebarAberta: false,
  paginas: [''],
  modulo: 1,
  titulo: '',
});

export default navegacaoStore;
