
<div id='pag7' style="margin: 0 15vw 0 5vw ">
  <div class="row" style="justify-content: center;">
    <div  style="min-width: 300px;justify-content: center;text-align: center;width: 30%;">
      <img alt='' style="width: 100%;" src="assets/Casos de uso/ilustras/ilustra 07 casos.png"/>
      
    </div>
    <div  style="padding: 0 5vw 0 0; min-width: 300px; width: 70%;">
      <h2 style="margin-top: 3rem !important; margin-bottom: 3rem !important">Caso de uso no setor automotivo:</h2>
      

      <h5>Qual era a necessidade?</h5>

      <p>Um grupo de concessionárias nos procurou relatando a seguinte situação: diariamente, alguns funcionários da empresa monitoravam os grandes portais de anúncios de carros, procurando por oportunidades de negócio. Toda vez que um veículo fosse anunciado e se encaixava dentro de um perfil pré determinado (fosse para troca por um novo ou outro seminovo), o vendedor entrava em contato iniciando uma possível negociação. Além do processo completo ocupar todo o expediente do funcionário, estimava-se que aproximadamente 80% dos anúncios não eram verificados, ou seja, possíveis oportunidades não eram checadas.   </p>
    
      <img alt='' style='margin: 
 30px 30px  30px 0;width: 100%;' src='assets/Casos de uso/icones/car_image.webp'/>
      <h5 style="margin-top: 25px;">Solução entregue:</h5>
    
      <p>
        A solução proposta inicialmente foi criar um bot que realizasse um monitoramento dos portais 24 horas por dia, durante os 7 dias da semana, além de fazer uma classificação de forma automática, enviando um WhatsApp para o colaborador da empresa quando a oportunidade fosse encontrada, para que assim o mesmo pudesse iniciar a negociação.     </p>
      <p>
        Em um segundo momento também implementamos o envio automático de mensagens no chat, passando os dados do vendedor para o interessado. Atualmente nosso cliente recebe os leads através de uma solução automatizada.       </p>
      
      <p>Ficou interessado em gerar leads para a sua empresa? Envie uma mensagem com sua necessidade para que possamos te ajudar! </p>

    </div>
  </div>
</div>

<div class="album py-5 bg-light" style="">
  <div class="">
    <h2 style="text-align: center;">Veja outros casos de uso</h2>
    <div class="row "style="width:70vw;margin: 50px 15vw 0 15vw;">



      <div class="col" style='margin:5px;'>
        <a href="#/casos/nfse">
          <div class="card shadow-sm" style='height: 100%'>
            <h3  style="text-align: center;margin:15px">Leitor de NFS-e</h3>
            <div class="card-body" style='min-width: 250px;text-align:center; padding: 0px;'>
              <img alt='' src='assets/Casos de uso/icones/inventti_use_case-3.png' style="width: 100%; height:100%; margin:0;padding:0" class=''/>
              
              
            </div>
          </div>
        </a>
      </div>

      <div class="col" style='margin:5px;'>
        <a href="#/casos/imobiliario">
          <div class="card shadow-sm" style='height: 100%;'>
            <h3  style="text-align: center;margin:15px">Dados de imóveis</h3>
            <div class="card-body" style='min-width: 250px; text-align:center; padding: 0px'>
              
              <img alt='' src='assets/Casos de uso/icones/imob.webp' style="width: 100%; height:100%; margin:0;padding:0" class=''/>
              
            </div>
          </div>
        </a>
      </div>


      <div class="col" style='margin:5px;'>
          <a href="#/casos/licitacoes">
            <div class="card shadow-sm" style='height: 100%;'>
              <h3  style="text-align: center;margin:15px">Dados de licitações</h3>
              <div class="card-body" style='min-width: 250px;text-align:center; padding:0px;'>
                <img alt='' src='assets/Casos de uso/icones/licita.jpg' style="width: 100%; height:100%; margin:0;padding:0" class=''/>
                
              </div>
            </div>
          </a>
        </div>
    
    
    
    
    </div>
  </div>    
</div>







<div class="" style="">
  <div class="col" style=''>
    <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0 15vw;'>
      <div class="card-body" style='min-width: 300px;'>
        <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
    a datallog pode ajudar seu negócio?</h2>
      </div>    
      <div style="margin:0 50px 30px 50px ;text-align:right;">
        <a href="#/contato" style="display: inline-block;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #fff;
        padding: 0.375rem 0.75rem;
        color: #000;
        border: 2px solid #000;
        padding: 10px 22px;
        border-radius: 0;
        font-weight: 800;
        margin: 0;
        width: auto;
        position: relative;
        font-size: 1rem;
        line-height: 1rem;
        text-transform: uppercase;
        letter-spacing: .5px;
        -webkit-transition: background .4s,border .4s,color .4s;
        transition: background .4s,border .4s,color .4s;
        white-space: break-spaces;
        ">
    <span>Conte-nos como podemos te ajudar</span>
    
    <div style="
    
    display: block;
    position: absolute;
    top: calc(100% + 2px);
    left: calc(0% - 2px);
    width: calc(100% + (2 * 2px));
    border-top: 4px solid #000;
    border-left: 8px solid rgba(0,0,0,0);
    border-right: 8px solid rgba(0,0,0,0);
    height: 0;
    margin:0;
    padding:0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;"></div>
        </a>
      </div>
    </div>    
  </div>
</div>    


