<script>
  export let idUnico = 'whatever';
  export let color;
  export let colorido;
  let root;

  import { onMount } from 'svelte';
  //ACCORDION LOADER
  onMount(() => {
    if (color) {
      root.style.setProperty('--COR_MODULO', `var(--${color})`);
      root.style.setProperty('--COR_MODULO_FORTE', `var(--${color}_FORTE)`);
      root.style.setProperty('--COR_MODULO_FRACO', `var(--${color}_FRACO)`);
      root.style.setProperty('--COR_MODULO_SUPER_FRACO', `var(--${color}_SUPER_FRACO)`);
      color === 'AMARELO' &&
        root.querySelectorAll('.button h3').forEach((element) => {
          element.setAttribute('style', `color: var(--AMARELO_SUPER_FORTE) !important`);
        });
    }

    if (colorido) {
      for (let i = 0; i < colorido.length; i++) {
        let element = root.querySelectorAll('#accordion > div')[i];
        element.style.setProperty('--COR_MODULO', `var(--${colorido[i]})`);
        element.style.setProperty('--COR_MODULO_FORTE', `var(--${colorido[i]}_FORTE)`);
        element.style.setProperty('--COR_MODULO_FRACO', `var(--${colorido[i]}_FRACO)`);
        element.style.setProperty('--COR_MODULO_SUPER_FRACO', `var(--${colorido[i]}_SUPER_FRACO)`);
        colorido[i] === 'AMARELO' &&
          element.querySelectorAll('.button h3').forEach((element) => {
            element.setAttribute('style', `color: var(--AMARELO_SUPER_FORTE) !important`);
          });
      }
    }

    document.querySelectorAll(`#${idUnico} #accordion > div`).forEach((element, i) => {
      let button = element.querySelector('.button');
      let content = element.querySelector('.content');
      let contentId = `${idUnico}-content-${i}`;
      let labelId = `${idUnico}-button-${i}`;
      button.setAttribute('id', labelId);
      button.setAttribute('data-toggle', 'collapse');
      button.setAttribute('data-target', `#${contentId}`);
      button.setAttribute('aria-expanded', 'false');
      button.setAttribute('aria-controls', contentId);
      button.innerHTML += `
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="29" viewBox="0 0 22 21.44"><defs><style>.cls-1{fill:white;}</style></defs><g id="Camada_2" data-name="Camada 2"><g id="Layer_1" data-name="Layer 1"><path class="cls-1" d="M11.56,21.1a1.18,1.18,0,0,1-1.67,0L.35,11.56a1.17,1.17,0,0,1,0-1.67L9.89.35a1.17,1.17,0,0,1,1.67,0l1.09,1.09a1.19,1.19,0,0,1,0,1.68L6.71,8.76H20.82A1.18,1.18,0,0,1,22,9.94v1.57a1.18,1.18,0,0,1-1.18,1.18H6.71l5.92,5.63a1.18,1.18,0,0,1,0,1.69Z"/></g></g></svg>
        </i>`;
      content.setAttribute('id', contentId);
      content.setAttribute('aria-labelledby', labelId);
      content.setAttribute('data-parent', `#${idUnico} #accordion`);
      content.className = 'content collapse';
      // if (i === 0) {
      //   content.className = 'content collapse show';
      //   button.setAttribute('aria-expanded', 'true');
      // }
    });
  });
</script>

<section class="container" id={idUnico} bind:this={root}>

      <div id="accordion">
        <slot />

  </div>
</section>

<style>
  .container{ 
    padding: 0;
  }
  #accordion {
    position: relative;
    margin:0;
    padding: 0;
  }
  #accordion > :global(div) {
    width: 97%;
    margin: 25px 0 55px 0;
    transform-style: preserve-3d;
  }
  #accordion :global(.button) {
    all: unset;
    cursor: pointer;
    width: 100%;
    background: #01e7e5;
    border: 1px solid var(--COR_MODULO);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    font-weight: 700;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    transition: all 200ms ease;
    margin-bottom: -30px;

    transform: translateZ(0.1px);
  }
  #accordion :global(.button > h3) {
    font-family: var(--FONTE_SANS);
    /* 
    cor módulo 1 especial, alterar para os outros
    color: var(--COR_MODULO); 
    */
    color: #000;
    font-weight: 700;
    font-size: 18px;
    margin: 20px 0 20px 20px;
  }
  #accordion :global(.content) {
    transform-style: preserve-3d;
    transform: translateZ(-0.1px);
    color: #000;
    font-size: 18px;
  }
  #accordion :global(.content > div) {
    transform-style: preserve-3d;
    transform: translateZ(-0.1px);
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background: #d8d8d8;
    padding: 60px 30px 30px 30px;
    position: relative;
    transition: all 100ms ease;
  }
  #accordion :global(.button > i) {
    margin: 20px 25px;
    transition: all 200ms ease;
  }

  #accordion :global(.button i path) {
    fill: #d8d8d8;
  }

  #accordion :global(.button[aria-expanded='true']) {
    background: #01e7e5;
    box-shadow: none;
  }
  #accordion :global(.button[aria-expanded='true'] > h3) {
    /* 
    Cor do módulo 1 especial, alterar para os outros
    color: var(--COR_MODULO_FORTE);
    */
  }

  #accordion :global(.button[aria-expanded='true'] i) {
    transform: rotate(-90deg);
  }
  #accordion :global(.button[aria-expanded='true'] i path) {
    fill: #fff;
  }
</style>
