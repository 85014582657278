<script>
    

  </script>
  
  
  <style>
  * {
    box-sizing: border-box;
  }
  
  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  .row{
    justify-items: center;
  }
  
  [class*="col-"] {
    float: left;
    padding: 15px;
  }
 
  

  h6{
    margin:5px 0;
    padding:  0;
  }




.col-3 {width: 25%;}

.col-5 {width: 30%;}
.col-6 {width: 50%;}
.col-7 {width: 40%;}

.col-12 {width: 100%;}

.trabalhos{
  padding-top: 10px;
  
}
.trabalhos p{
  margin:0;
}
.trabalhos h4{
  margin:15px 0;
}

li {
    font-family: var(--FONT_CABIN);
    font-size: 18px;
    list-style-image: url("../../../assets/images/recursos/list_bullet.svg");
    margin-bottom: 0;
  }
  li:not(:last-child) {
    margin-bottom: 0;
  }
</style>


























  <div class="" id='' style="margin: 0 16vw 0 11vw">
    <div class="row" style="justify-content: center;">
      <div class="col-5" style="width: 300px;justify-content: center;text-align: center;">
        <img alt='' style="margin:10%  10% 0 0;width: 90%;" src="assets/Consultas disponiveis/Receita.png"/>
      </div>
      <div class="col-7" style="padding: 0; min-width: 300px;">
        <div class="trabalhos">
            <h2>Receita Federal - Simples Nacional
            </h2>
              <h4>Para que serve?</h4>
            <p style="padding: 0 ;">
              Na consulta Simples Nacional você verifica se uma empresa é optante do Simples Nacional.
            </p> <p>
              O Simples Nacional é um regime diferenciado de arrecadação que pode ser aplicado a microempresas ou empresas de pequeno porte.
              
                          </p>
         
            <div><h4>URL da consulta:</h4>
            <a href="https://consopt.www8.receita.fazenda.gov.br/consultaoptantes" style="">https://consopt.www8.receita.fazenda.gov.br/consultaoptantes</a>


              <p style="margin:30px 0 0 0;"><b><br>Quer saber mais informações? </b><br>Acesse <a href="http://www8.receita.fazenda.gov.br/SimplesNacional/">http://www8.receita.fazenda.gov.br/SimplesNacional/</a></p>

              <br><br><a href="#/consultas" style="">
                <h5 style="">Voltar para a lista de consulta</h5>
          </a></div>

            

        </div>
        <div class="bloco" style='margin-top:30px;'>
          <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0; display: flex;
flex-direction: row; padding: 10px 0px 10px 0px; justify-content: center;'>
            <div class="card-body" style='flex-grow: 1;'>
              <h2 style='margin:0;padding: 0vw;'>Ficou com alguma dúvida?</h2>
            </div>    
            <div style="margin:auto; text-align:right; flex-grow: 1; display: flex; align-content: center; justify-content: center;">
    <a href="#/contato" style="display: inline-block;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    padding: 0.375rem 0.75rem;
    color: #000;
    border: 2px solid #000;
    padding: 15px 22px 15px 22px;
    border-radius: 0;
    font-weight: 800;
    margin: 0;
    width: auto;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: .5px;
    -webkit-transition: background .4s,border .4s,color .4s;
    transition: background .4s,border .4s,color .4s;
    white-space: break-spaces;
    text-decoration: none;
    ">
<span>Como podemos te ajudar?</span>

<div style="

display: block;
position: absolute;
top: calc(100% + 2px);
left: calc(0% - 2px);
width: calc(100% + (2 * 2px));
border-top: 4px solid #000;
border-left: 8px solid rgba(0,0,0,0);
border-right: 8px solid rgba(0,0,0,0);
height: 0;
margin:0;
padding:0;
-webkit-box-sizing: border-box;
box-sizing: border-box;"></div>
    </a>
      </div>  </div>    </div>
      </div>
    </div>
  </div>





