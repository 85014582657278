<script>
    

  </script>
  
  
  <style>
  * {
    box-sizing: border-box;
  }
  a{
    color: black;
    text-decoration: underline;
  }
  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  .row{
    justify-items: center;
  }
  
  [class*="col-"] {
    float: left;
    padding: 15px;
  }
  button{
    border:1px solid black;
    box-shadow: 0px 10px 14px -3px #000000; 
  padding: 0 1rem;
  }
  
  .col-5 {width: 25%;}
  .col-7 {width: 75%;}
  
.icones{
    min-width: 100px;
    width:10vw;
    margin:-20px;
    position: absolute; 
    bottom:0;
}
* {
  box-sizing: border-box;
}
#pag1{
  background-image: url('https://i.postimg.cc/MpRYzvL0/linha-01.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  
}
#pag2{
  background-image: url('https://i.postimg.cc/Y9zMPhPq/linha-02.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  
}
#pag3{
  background-image: url('https://i.postimg.cc/FKvkCbkJ/linha-03.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  
}
#pag4{
  background-image: url('https://i.postimg.cc/8C6T4XhD/linha-04.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  
}
#pag5{
  background-image: url('https://i.postimg.cc/XvRqnpM0/linha-05.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  
}

.header {
  padding: 50px 15px 15px 15vw ;
  margin-top: -5vw;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.caixa{
  background-color: burlywood;
  margin-top:-100px;
  padding: 110px 20px 15px 20px;
  min-height:310px;
  width: 100%;


}
.row{
  justify-items: center;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}
button{
  border:1px solid black;
  box-shadow: 0px 10px 14px -3px #000000;
}


.col-3 {width: 25%;}

.col-5 {width: 30%;}
.col-6 {width: 50%;}
.col-7 {width: 70%;}

.col-12 {width: 100%;}

.trabalhos{
  padding-top: 10px;
  
}
.trabalhos-ultimo{
  padding-top: 10px;
  padding-left: 5vw;

}
.trabalhos p{
  margin:0;
}
.trabalhos h4{
  margin:0;
}
.trabalhos-ultimo h4{
  margin:0;
}

li {
    font-family: var(--FONT_CABIN);
    font-size: 18px;
    list-style-image: url("../../../assets/images/recursos/list_bullet.svg");
  }
  li:not(:last-child) {
   margin-bottom: 10px;
  }
  span {
    position: relative;
    left: 7px;
  }
  .list-estrutura li {
    font-family: var(--FONT_CABIN);
    font-size: 18px;
  }
  .list-estrutura li:not(:last-child) {
    margin-bottom: 10px;
  }
  .list-estrutura {
    padding-left: 25px;
  }
  .list-estrutura .li-num {
    text-align: right;
    padding-right: 3px;
  }

  .estrut {
    font-family: var(--FONT_CABIN);
    text-transform: uppercase;
    color: var(--COR_MODULO);
    font-weight: 400;
  }
  .estrut-uni {
    color: var(--COR_MODULO);
    font-weight: 700;
    text-transform: uppercase;
    font-family: var(--FONT_CABIN);
  }
  .li-num {
    font-family: var(--FONT_CABIN);
    color: var(--COR_MODULO);
    font-weight: 700;
  }

</style>



<div id='pag3' style="margin: 0vw 10vw 0vw 5vw;">
    <div class="row" style="justify-content: center;">
      <div class="col-5" style="width: 300px;justify-content: center;text-align: center;">
        <img alt='' style="margin:0 -50px 0 0;width: 100%;" src="assets/Consultas disponiveis/ilustra 01 consultas.png"/>
      </div>
      <div class="col-7" style="padding: 0 15px; min-width: 300px;">
        <div class="trabalhos">
            <h2>Consultas disponíveis</h2>
            <p style="padding: 30px 0;">
                Confira abaixo todas as consultas que nossa tecnologia gera de forma automatizada.
Clique sobre o nome da consulta para ver mais detalhes. Não encontrou a certidão que
estava buscando? Envie uma mensagem com sua necessidade para que possamos analisar.
            </p>
            <ul>
<li>
<a href="#/consultas/ANTT" style="text-decoration: underline;">ANTT - Registro Nacional de Transportadores Rodoviários de Cargas_files</a>              
</li>
<li>
<a href="#/consultas/Banco_do_Brasil" style="text-decoration: underline;">Banco do Brasil - Relação de Pessoas Impedidas de Licitar e Contratar</a>              
</li>
<li>
<a href="#/consultas/CCC" style="text-decoration: underline;">CCC - Cadastro Centralizado de Contribuinte</a>              
</li>
<li>
<a href="#/consultas/CEIS">CEIS - Cadastro de Empresas Inidôneas e Suspensas</a>              
</li>
<li>
<a href="#/consultas/CENPROT">CENPROT - Central Nacional de Protesto</a>              
</li>
<li>
<a href="#/consultas/CNEP">CNEP - Cadastro Nacional Empresas Punidas</a>              
</li>
<li>
<a href="#/consultas/CNJ">CNJ - Cadastro Nacional de Condenações Cíveis por Ato de Improbidade Administrativa e Inelegibilidade</a>              
</li>
<li>
<a href="#/consultas/Detran_AC">Detran AC - Emissão dos Débitos do Veículo</a>              
</li>
<li>
<a href="#/consultas/Detran_AL">Detran AL - Consulta de Veículo</a>              
</li>
<li>
<a href="#/consultas/Detran_AM">Detran AM - Situação do Veículo</a>              
</li>
<li>
<a href="#/consultas/Detran_AP">Detran AP - Consulta de Veículos</a>              
</li>
<li>
<a href="#/consultas/Detran_BA">Detran BA - Consulta Licenciamento</a>              
</li>
<li>
<a href="#/consultas/Detran_CE">Detran CE - Consulta Completa do Veículo</a>              
</li>
<li>
<a href="#/consultas/Detran_DF">Detran DF - Consulta Veículos</a>              
</li>
<li>
<a href="#/consultas/Detran_ES">Detran ES - Informações do Veículo</a>              
</li>
<li>
<a href="#/consultas/Detran_GO">Detran GO - Situação do Veículo</a>              
</li>
<li>
<a href="#/consultas/Detran_MA">Detran MA - Dados do Veículo</a>              
</li>
<li>
<a href="#/consultas/Detran_MG">Detran MG - Situação do Veículo</a>              
</li>
<li>
<a href="#/consultas/Detran_MS">Detran MS - Consulta Débito de Veículos</a>              
</li>
<li>
<a href="#/consultas/Detran_MT">Detran MT - Consulta Veículo</a>             
</li>
<li>
<a href="#/consultas/Detran_PA">Detran PA - Consulta de Veículo Detalhada</a>             
</li>
<li>
<a href="#/consultas/Detran_PB">Detran PB - Consulta do Veículo</a>             
</li>
<li>
<a href="#/consultas/Detran_PE">Detran PE - Consulta de Placa</a>             
</li>
<li>
<a href="#/consultas/Detran_PI">Detran PI - Consulta Licenciamento</a>             
</li>
<li>
<a href="#/consultas/Detran_PR">Detran PR - Consulta Consolidada do Veículo</a>             
</li>
<li>
<a href="#/consultas/Detran_RJ">Detran RJ - Cadastro de Veículo</a>             
</li>
<li>
<a href="#/consultas/Detran_RN">Detran RN - Consulta do Veículo</a>             
</li>
<li>
<a href="#/consultas/Detran_RO">Detran RO - Consulta de Veículos</a>             
</li>
<li>
<a href="#/consultas/Detran_RR">Detran RR - Consulta Débitos de Veículo</a>             
</li>
<li>
<a href="#/consultas/Detran_RS">Detran RS - Consulta Individual de Veículo</a>             
</li>
<li>
<a href="#/consultas/Detran_SC">Detran SC - Consulta Completa de Veículo</a>             
</li>
<li>
<a href="#/consultas/Detran_SE">Detran SE - Consulta Dados de Veículo</a>             
</li>
<li>
<a href="#/consultas/Detran_SP">Detran SP - Pesquisa de débitos, restrições e vistorias</a>             
</li>
<li>
<a href="#/consultas/Detran_TO">Detran TO - Consulta Veículo</a>             
</li>
<li>
<a href="#/consultas/Fazenda_MG">Fazenda MG - Certidão de Débitos Tributários</a>             
</li>
<li>
<a href="#/consultas/Fazenda_SP_CADIN">Fazenda SP - CADIN</a>             
</li>
<li>
<a href="#/consultas/Fazenda_SP">Fazenda SP - Certidão Negativa de Débitos Tributários Não Inscritos</a>             
</li>
<li>
<a href="#/consultas/FGTS">FGTS - Certificado de Regularidade</a>             
</li>
<li>
<a href="#/consultas/IBAMA">IBAMA - Certidão Negativa de Débito</a>             
</li>
<li>
<a href="#/consultas/INSS">INSS - Qualificação Cadastral</a>             
</li>
<li>
<a href="#/consultas/Junta_Comercial">Junta Comercial do Estado de São Paulo</a>             
</li>
<li>
<a href="#/consultas/Lava_Jato">Lava Jato</a>             
</li>
<li>
<a href="#/consultas/MEI">MEI - Comprovante</a>             
</li>
<li>
<a href="#/consultas/MPSP">Ministério Público do Estado de São Paulo - Certidão Cível e Criminal</a>             
</li>
<li>
<a href="#/consultas/MPF">Ministério Público Federal - Certidão Negativa</a>             
</li>
<li>
<a href="#/consultas/MTE">MTE - Certidão de Débitos Trabalhistas</a>             
</li>
<li>
<a href="#/consultas/PGE_SP_CERTIDAO">PGE SP - Certidão Negativa de Débitos Tributários da Dívida Ativa</a>             
</li>
<li>
<a href="#/consultas/PGE_SP_DIVIDA">PGE SP - Dívida Ativa</a>             
</li>
<li>
<a href="#/consultas/PGFN">PGFN - Lista de Devedores</a>             
</li>
<li>
<a href="#/consultas/Prefeitura_BH">Prefeitura BH - Certidão Negativa de Débitos</a>             
</li>
<li>
<a href="#/consultas/Prefeitura_SP_IMOB">Prefeitura SP - Certidão Conjunta de Débitos de Tributos Imobiliários</a>             
</li>
<li>
<a href="#/consultas/Prefeitura_SP_MOB">Prefeitura SP - Certidão Conjunta de Débitos de Tributos Mobiliários</a>             
</li>
<li>
<a href="#/consultas/Prefeitura_SP_IPTU">Prefeitura SP - Certidão de Dados Cadastrais do Imóvel - IPTU</a>             
</li>
<li>
<a href="#/consultas/Prefeitura_SP_EXTRATO">Prefeitura SP - Extrato de Débitos de IPTU</a>             
</li>
<li>
<a href="#/consultas/Receita1">Receita - Certidão Negativa de Débitos Relativos às Contribuições Previdenciárias e às de Terceiros</a>             
</li>
<li>
<a href="#/consultas/Receita2">Receita Federal - Cartão CNPJ</a>             
</li>
<li>
<a href="#/consultas/Receita3">Receita Federal - Certidão de Débitos Relativos a Créditos Tributários Federais e à Dívida Ativa da União</a>             
</li>
<li>
<a href="#/consultas/Receita4">Receita Federal - Certidão Negativa De Débitos Relativos aos Tributos Federais e à Dívida Ativa Da União De Imóvel Rural</a>             
</li>
<li>
<a href="#/consultas/Receita5">Receita Federal - Comprovante de Situação Cadastral no CPF</a>             
</li>
<li>
<a href="#/consultas/Receita6">Receita Federal - Quadro de Sócios e Administradores</a>             
</li>
<li>
<a href="#/consultas/Receita7">Receita Federal - Simples Nacional</a>             
</li>
<li>
<a href="#/consultas/Registradores">Registradores - Matrícula Online</a>             
</li>
<li>
<a href="#/consultas/Secretaria">Secretaria do Patrimônio da União - Certidão de Situação de Ocupação_Aforamento</a>             
</li>
<li>
<a href="#/consultas/SICAF">SICAF - Consulta Restrição Contratar Administração</a>             
</li>
<li>
<a href="#/consultas/SICAR">SICAR - Demonstrativo do CAR</a>             
</li>
<li>
<a href="#/consultas/SNCR">SNCR - Emissão do Certificado de Cadastro do Imóvel Rural (CCIR)</a>             
</li>
<li>
<a href="#/consultas/TCU">TCU - Relação de Inidôneos</a>             
</li>
<li>
<a href="#/consultas/TJMG">TJMG - Consulta Processual</a>             
</li>
<li>
<a href="#/consultas/TJRJ">TJRJ - Consulta Processual</a>             
</li>
<li>
<a href="#/consultas/TJSP">TJSP - Certidões dos Distribuidores de 1º Grau</a>             
</li>
<li>
<a href="#/consultas/TRF">TRF - Tribunal Regional Federal - Certidão de Distribuição</a>             
</li>
<li>
<a href="#/consultas/TRT">TRT - Certidão de Ações Trabalhistas</a>             
</li>
<li>
<a href="#/consultas/TST">TST - Banco de Falências</a>             
</li>
<li>
<a href="#/consultas/TST2">TST - Certidão de Débito Trabalhista</a>             
</li>
            </ul>
        </div>
      </div>
    </div>
  </div>


















