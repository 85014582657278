<script>
  import active from 'svelte-spa-router/active';
  import { link, location } from 'svelte-spa-router';
  import Router from 'svelte-spa-router';
  import { onMount, afterUpdate, onDestroy } from 'svelte';

  import WHATS from './components/whats.svelte';
  import Sidebar from './components/sidebar.svelte';
  import Footer from './components/footer.svelte';
  import Container from './components/layout/container.svelte';
  import routes from './routes';
  import navegacaoStore from './store';
  import conteudo from './conteudo';
  import Header from './components/header.svelte';
  import Chat from './components/chat.svelte';
  let root;
  let selected;

  let paginaAtual = '';

  let dados = {
    ...conteudo,
  };

  navegacaoStore.set(dados);
  navegacaoStore.subscribe((dadosStore) => {
    dados = dadosStore;
  });

  


  const inicializacao = (callback) => {
    AOS.init();
    j$('html').animate({ scrollTop: 0 }, 500);
    check_all_images_complete(() => {
      /**
       * Inicializar funções necessárias aqui
       */
      refresh_objects_listeners();
      AOS.refreshHard();
    });
  };

  onMount(() => inicializacao());

  afterUpdate(() => {
    if ($location !== paginaAtual) {
      inicializacao();
    }
    AOS.refreshHard();
    paginaAtual = $location;
  });

</script>

<svelte:head>
  <title>
    Datallog
  </title>

</svelte:head>

<main bind:this={root}>
  <Header/>


  <div class="page-content">
    <Router {routes} />
  </div>



  <br />
<WHATS/>
  <Footer />
</main>

<style>

  .page-content {
    width: 100vw;
    overflow: hidden;
    background-position-x: center;
    background-position-y: top, 50vw, 100vw,150vw,200vw;
    background-repeat: no-repeat;
    background-size: contain;
  }
</style>
