
  
<style>
  li {
      font-family: var(--FONT_CABIN);
      font-size: 18px;
      list-style-image: url("../../../assets/images/recursos/list_bullet.svg");
      margin-bottom: 0;
    }
    #toggle-1 {
     display:none;
  }
  
  label { 
    -webkit-appearance: push-button;
    -moz-appearance: button; 
    display: inline-block;
    cursor: pointer;
    padding: 5px;
  }
  
  /* CSS padrão da div */
  #mostra {
    display:none;
  }
  
  /* CSS quando o checkbox está marcado */
  #toggle-1:checked ~ #mostra {
    display:block;
    z-index: 99;
    width: 25vw;
    min-width: 200px;
  }
    input{
      width: 100%;
      height: auto;
    }
    #input-text{
      width: 100%;
      height: auto;
    }
  .contato{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 25vw;
    background-color: gray;
    padding: 0 2vw;
    justify-content: center;
  }
  .contato p{
    padding: 2vh 0 0 0;
    margin:  0;
  }
  .contato h4{
    margin-top: 5vh;
  }
  
  .botao{
  
    text-align: center;
  }
  button{
    width: auto;
    padding: 0 2vw;
    margin: 5vh auto;
    background-color: #01e7e5;
  }
  h4{
    widows: 100%;
  }
  #input{
    position:fixed;
    right: 0;
    bottom: 0;
    border: 1px solid var(--COR_MODULO);
    width: 25vw;
    min-width: 200px;
    background-color:#01e7e5;
    z-index: 100;
    padding: 0;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .row::after {
    content: "";
    clear: both;
    display: table;
  }
  button{
    padding: 0 1rem;
  }
  
  
  .row{
    justify-items: center;
  }
  
  [class*="col-"] {
    float: left;
    padding: 15px;
  }
  button{
    border:1px solid black;
    box-shadow: 0px 10px 14px -3px #000000;
  }
  
  
  .col-4 {width: 20%;}
  
  .col-8 {width: 45%;}
  
  li {
      font-family: var(--FONT_CABIN);
      font-size: 18px;
      list-style-image: url("../../../assets/images/recursos/list_bullet.svg");
    }
    li:not(:last-child) {
     margin-bottom: 10px;
    }
  
  
  .modelo{
    margin-right: 15vw; padding-right: 320px;min-height: 70vh;
  }
  
  
  #fim{
    display:none;
  }
  #fixo{
    width: 320px;
        justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle;
        position: absolute;
        top: 15vh;
        right: 15vw;
        z-index: 5;
    height:300px;
  }
  #mensagem p{
      margin:  20px 0 0 0;
  }
  
  @media (max-width:1200px){
  #fixo{
    display:none;
  }
  #fim{
    display:inline;
    width: 70vw;
    margin: 0 15vw;
    padding: 0 15vw;
        justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle;
  }
  .modelo{
    margin-right: 0; padding-right: 0;
  }
  
  
  
  }
  </style>
  
  
  
  
  
  
  
    
  <div class='modelo'  id="SDK-Plataforma" style='margin-top:35px'>
    <div class="row" style="justify-content: center">
      <div class="col-4" style="min-width: 300px;justify-content: center;text-align: center;">
        <img alt='' style="width: 100%;" src="assets/Contato/ilustra 02 contato.png"/>
      </div>
      <div class="col-8" style=" min-width: 300px;padding: 0 5vw;">
        <div class="">
          <h2>Projetos para coleta de dados ou RPA web</h2>
          <p>Solicite uma solução completa de ponta a ponta para as suas
          necessidades de extração de dados e automação web.</p>
          <h2 style='padding:0 0 10px 0;margin-top:0'>Porque Datallog?</h2>
      
          <ul>
              <li>
                  <h5>Especialista em extração de dados</h5>
              </li>
              <p>Da analise inicial à entrega do projeto, você terá acesso a especialistas em coleta de dados e automação web.</p>
              <li>
                  <h5>POC</h5>
              </li>
              <p>Começamos com uma prova de conceito e, uma vez aprovado entregamos a solução completa já integrada aos seus sistemas.</p>
              <li>
                  <h5>Confiabilidade</h5>
              </li>
              <p>Quaisquer que sejam seus desafios, integrações personalizadas, agilidade na adição de novos coletores ...
                  temos uma equipe qualificada pra atender suas necessidades.</p>
              <li>
                  <h5>Escalabilidade</h5>
              </li>
              <p>Nossas soluções se ajustam a medida que sua demanda cresce, de algumas dezenas a milhões
                  de páginas podem ser processadas.</p>
          </ul>
  </div></div></div></div>
  
  
  
  
  
  
  

  
  <div id='fixo' class="chat" style=''>
    <div class="card-body" style='background-color: #d8d8d8;'>
        <div id="mensagem" class=''>
            <h4 style="padding: 0 0 20px 0;text-align: center;margin:0;">Alguma outra pergunta?</h4>
            
            <p>Seu nome</p> 
            <input class='input-box' style="margin-bottom: 15px;" type="text">
            <p>E-mail que você usa diariamente</p> 
            <input class='input-box' style="margin-bottom: 15px;" type="email">
            <p>Telefone</p> 
            <input class='input-box' style="margin-bottom: 15px;" type="tel">
            <p>Sua dúvida</p> 
            <textarea id="input-text" rows="4"></textarea>
            
            <div class='botao' style='padding: 15px;width: 100%;'><a href="#/contato" style="display: inline-block;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #01e7e5;
    padding: 0.375rem 0.75rem;
    color: #000;
    border: 2px solid #000;
    padding: 15px 22px 0 22px;
    border-radius: 0;
    font-weight: 800;
    margin: 0;
    width: auto;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: .5px;
    -webkit-transition: background .4s,border .4s,color .4s;
    transition: background .4s,border .4s,color .4s;
    white-space: break-spaces;
    ">
<span>Enviar mensagem</span>

<div style="

display: block;
position: absolute;
top: calc(100% + 2px);
left: calc(0% - 2px);
width: calc(100% + (2 * 2px));
border-top: 4px solid #000;
border-left: 8px solid rgba(0,0,0,0);
border-right: 8px solid rgba(0,0,0,0);
height: 0;
margin:0;
padding:0;
-webkit-box-sizing: border-box;
box-sizing: border-box;"></div>
    </a></div>
        </div>
    </div>
  </div>
  
  <div id='fim' class="chat" style=''>
    <div class="card-body" style='background-color: #d8d8d8;'>
        <div id="mensagem" class='' style='padding: 0 15vw;'>
            <h4 style="padding: 0 0 20px 0;text-align: center;margin:0;">Alguma outra pergunta?</h4>
            
            <p>Seu nome</p> 
            <input class='input-box' style="margin-bottom: 15px;" type="text">
            <p>E-mail que você usa diariamente</p> 
            <input class='input-box' style="margin-bottom: 15px;" type="email">
            <p>Telefone</p> 
            <input class='input-box' style="margin-bottom: 15px;" type="tel">
            <p>Sua dúvida</p> 
            <textarea id="input-text" rows="4"></textarea>
            
            <div class='botao' style='padding: 15px;width: 100%;'><a href="#/contato" style="display: inline-block;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #01e7e5;
    padding: 0.375rem 0.75rem;
    color: #000;
    border: 2px solid #000;
    padding: 15px 22px 0 22px;
    border-radius: 0;
    font-weight: 800;
    margin: 0;
    width: auto;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: .5px;
    -webkit-transition: background .4s,border .4s,color .4s;
    transition: background .4s,border .4s,color .4s;
    white-space: break-spaces;
    ">
<span>Enviar mensagem</span>

<div style="

display: block;
position: absolute;
top: calc(100% + 2px);
left: calc(0% - 2px);
width: calc(100% + (2 * 2px));
border-top: 4px solid #000;
border-left: 8px solid rgba(0,0,0,0);
border-right: 8px solid rgba(0,0,0,0);
height: 0;
margin:0;
padding:0;
-webkit-box-sizing: border-box;
box-sizing: border-box;"></div>
    </a></div>
        </div>
    </div>
  </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  