
<script>
    

  </script>
  
  
  <style>
  * {
    box-sizing: border-box;
  }
  
  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  .row{
    justify-items: center;
  }
  
  button{
    border:1px solid black;
    box-shadow: 0px 10px 14px -3px #000000; 
  padding: 0 1rem;
  }
  


</style>


































<div class="album py-5 bg-light" style="padding:0;">
    <div class="container">
        <div style='text-align:center;vertical-align: center; '>
            <h3 style="margin: 30px;">Fique por dentro do mercado de dados.</h3>
            <h5 style="color: #01e7e5; font-">Últimas publicações</h5>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col" style='padding:1vw;text-align: center;min-width: 300px;vertical-align: middle;'>
                <img alt='' style="width: 100%; max-width: 300px;" src="assets/Blog/ilustra 01.png"/>
            </div>
            <div class="col" style='margin:1vw;text-align: center;'>
                <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
                    <div class="card-body" style='min-width: 250px; padding:0;'>
                        <img alt='' src='assets/Casos de uso/Links/www.png' style="width: 90%; margin:0;padding:0" class=''/>
                        <h5> Aqui vai uma notícia </h5>
                        <p>Loren ipsulum Loren ipsulum Loren ipsulum Loren ipsulum Loren ipsulum Loren ipsulum Loren ipsulum Loren ipsulum Loren ipsulum Loren ipsulum dolor</p>
                    </div>
                </div>
            </div>
        
            <div class="col" style='margin:1vw;text-align: center;'>
                <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
                    <div class="card-body" style='min-width: 250px; padding:0;'>
                        <img alt='' src='assets/Casos de uso/Links/yyy.png' style="width: 90%; margin:0;padding:0" class=''/>
                        <h5> Aqui vai uma notícia </h5>
                        <p>Loren oren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulu dolor</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col" style='margin:1vw;text-align: center;'>
                <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
                    <div class="card-body" style='min-width: 250px; padding:0;'>
                        <img alt='' src='assets/Casos de uso/Links/xxx.png' style="width: 90%; margin:0;padding:0" class=''/>
                        <h5> Aqui vai uma notícia </h5>
                        <p>Loren oren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulu dolor</p>
                    </div>
                </div>
            </div>
            <div class="col" style='margin:1vw;text-align: center;'>
                <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
                    <div class="card-body" style='min-width: 250px; padding:0;'>
                        <img alt='' src='assets/Casos de uso/Links/xxx.png' style="width: 90%; margin:0;padding:0" class=''/>
                        <h5> Aqui vai uma notícia </h5>
                        <p>Loren oren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulu dolor</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col" style='margin:1vw;text-align: center;'>
                <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
                    <div class="card-body" style='min-width: 300px;'>
                        <img alt='' src='assets/Casos de uso/Links/www.png' style="width: 90%; margin:0;padding:0" class=''/>
                        <p>Loren ipsulum dolor</p>
                    </div>
                </div>
            </div>
        
            <div class="col" style='margin:1vw;text-align: center;'>
                <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
                    <div class="card-body" style='min-width: 300px;'>
                        <img alt='' src='assets/Casos de uso/Links/yyy.png' style="width: 90%; margin:0;padding:0" class=''/>
                        <p>Loren ipsulum dolor</p>
                    </div>
                </div>
            </div>
        
            <div class="col" style='margin:1vw;text-align: center;'>
                <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
                    <div class="card-body" style='min-width: 300px;'>
                        <img alt='' src='assets/Casos de uso/Links/zzz.png' style="width: 90%; margin:0;padding:0" class=''/>
                        <p>Loren ipsulum dolor</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col" style='margin:1vw;text-align: center;'>
                <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
                    <div class="card-body" style='min-width: 300px;'>
                        <img alt='' src='assets/Casos de uso/Links/www.png' style="width: 90%; margin:0;padding:0" class=''/>
                        <div style=" margin:0;padding:2vw">
                            <h5> Aqui vai uma notícia destaque</h5>
                            <p >Loren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulumLoren ipsulum</p>
                        </div>
                        <div class="card-body" style='text-align: right;'>
                            <a href="https://www.linkedin.com/company/datallog/posts/?feedView=all"><button style="background-color: #01e7e5;">Outras noticias</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>
































































